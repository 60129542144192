$dx-primary: #fe7109 !default;
$dx-danger: #e81123 !default;
$dx-warning: #ff9c00 !default;
$dx-success: #10893e !default;
$dx-info: #0078d7 !default;

$dx-gray-100: #f7f8f9 !default;
$dx-gray-200: #f2f2f2 !default;
$dx-gray-300: #e5e5e5 !default;
$dx-gray-400: #ced4da !default;
$dx-gray-500: #adb5bd !default;
$dx-gray-600: #a1a1a1 !default;
$dx-gray-700: #495057 !default;
$dx-gray-800: #222222 !default;
$dx-gray-900: #212529 !default;

$dx-min-contrast-ratio: 1.8 !default;

// Common
$dx-color: $dx-gray-900 !default;
$dx-border-color: #ced4da !default;

$dx-state-selected-bg-alpha: 8% !default;
$dx-state-hover-bg-alpha: 4% !default;
$dx-state-hover-secondary-bg-alpha: 2% !default;
$dx-state-selected-hover-bg-alpha: 12% !default;

$dx-border-radius: 0.125rem !default;
$dx-border-radius-sm: 0.125rem !default;
$dx-border-radius-lg: 0.125rem !default;

// Button
$dx-btn-special-box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 20%) !default;

// TextEdit
$dx-text-edit-focus-shadow-size: 0px !default;

$dx-text-edit-btn-bg: transparent !default;
$dx-text-edit-btn-hover-bg: rgba(0, 0, 0, 0.05) !default;

// Tabs
$dx-tabs-separator-border-width: 2px !default;
$dx-tabs-tab-selected-element-height: 2px !default;

@import "../../bootstrap-compile/mixins/buttons";

@mixin button-secondary-custom() {
    @include button-variant(
        $dx-theme-btn-secondary-bg,
        $dx-theme-btn-secondary-bg,
        $hover-background: $dx-theme-btn-secondary-hover-bg,
        $hover-border: $dx-theme-btn-secondary-hover-bg
    );

    &:focus {
        background-color: $dx-theme-btn-secondary-bg;
    }
}

@mixin button-secondary-outline-custom() {
    $color: if(function-exists("color-contrast"), color-contrast($dx-theme-btn-secondary-bg), color-yiq($dx-theme-btn-secondary-bg));

    @include button-outline-variant(
        $color,
        $color-hover: $color,
        $active-background: $dx-theme-btn-secondary-hover-bg,
        $active-border: $color
    );
    @include button-shadow($color, $dx-theme-btn-secondary-bg, $btn-focus-width);
}

@mixin button-shadow($color, $button-main-color, $btn-focus-width) {
    $shadow: 0 0 0 $btn-focus-width rgba(mix($color, $button-main-color, 15%), .5);
    @include button-focused-shadow($shadow);
}
